<template>
  <main class="project-key-information-container">
    <section
      class="item-wrapper"
      v-for="(info, index) in key_information"
      :key="index"
    >
      <div class="date-wrapper primary-gradient-bg">
        <p class="-xstb">{{ info.date }}</p>
      </div>

      <div class="item-info" v-for="item in getItems[index]" :key="item">
        <p class="-xstb">{{ item.ref }}</p>
        <p class="-xstr">
          {{ item.notes }}
        </p>
      </div>

      <hr class="hr-form" />
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      key_information: [
        {
          date: "21/12/2022",
          items: [
            {
              ref: "T.2.050",
              notes: "Prepare and discuss final project brief",
            },
            {
              ref: "T.2.360",
              notes: "Assist lead designer",
            },
          ],
        },
        {
          date: "24/12/2022",
          items: [
            {
              ref: "T.2.361",
              notes: "Assist lead designer",
            },
          ],
        },
        {
          date: "21/12/2022",
          items: [
            {
              ref: "T.2.050",
              notes: "Prepare and discuss final project brief",
            },
            {
              ref: "T.2.360",
              notes: "Assist lead designer",
            },
          ],
        },
      ],
    };
  },

  computed: {
    getItems() {
      return this.key_information.map((info) => info.items);
    },
  },
};
</script>

<style scoped>
.project-key-information-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  overflow: auto;
  max-height: max(540px, 59vh);
}
.item-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  align-items: flex-start;
  padding-block: 4px;
  /* padding-inline: 1rem; */
}
.date-wrapper {
  border: 1px solid var(--gray6);
  padding: 4px;
  border-radius: 4px;
  color: var(--white1);
}

.item-info > p:first-child::before {
  content: "\2B9E";
  font-size: 10px;
  margin-right: 4px;
}
.item-info {
  display: flex;
  gap: 8px;
  padding-inline: 8px;
  text-align: left;
}
.hr-form {
  width: 100%;
}
</style>