<template>
  <main class="chart-wrapper">
    <section id="radial-chart">
      <apexchart
        type="radialBar"
        :options="chartOptions"
        :series="series"
        height="208"
      ></apexchart>
    </section>
  </main>
</template>

<script>
export default {
  props: ["stages", "units"],
  data() {
    return {
      maxVal: null,
      series: null,
      chartOptions: {
        chart: {
          type: "radialBar",
        },
        fill: {
          colors: "#FFF",
        },
        //Config starts here
        plotOptions: {
          radialBar: {
            offsetY: 7,
            track: {
              show: true,
              strokeWidth: "10%",
              margin: 1,
            },
            dataLabels: {
              name: {
                fontSize: "14px",
                fontFamily: "Montserrat-Regular",
                color: "#152243",
              },

              value: {
                offsetY: 5,
                fontSize: "12px",
                fontFamily: "Montserrat-Regular",
                formatter: function (val, w) {
                  const maxVal =
                    w.config.plotOptions.radialBar.dataLabels.value.maxVal;

                  return `Stage ${(val * maxVal) / 100 - 1}`;
                },
                color: "#152243",
              },
              total: {
                show: true,
                fontSize: "14px",
                fontFamily: "Montserrat-Regular",
                label: "Total Units",
                formatter: function (w) {
                  // console.log(w);
                  return w.globals.series.length;
                },
              },
            },
          },
        },
        labels: [],
      },
    };
  },
  created() {
    this.maxVal = this.stages.length;
    this.setSeries();

    //needed to set the max value on the chart
    this.setChartMaxVal();
    this.setChartColors();
    this.setLabels();
  },
  methods: {
    valuesToPercentage(stage_values) {
      //The '1' is mentioned so we can show the 0 value on the chart
      return stage_values.map((stage_values) => {
        return ((stage_values + 1) * 100) / this.maxVal;
      });
    },

    setChartColors() {
      //returns array of color_code property from stages array where stage_value matches the stage of the unit
      if (this.units.length) {
        this.chartOptions.fill.colors = this.units.map((unit) => {
          const stage = this.stages.find(
            (stage) => stage.stage_value == unit.stage_value
          );

          return stage.color_code;
        });
      }
    },
    setLabels() {
      this.chartOptions.labels = this.units.map((unit) => {
        return unit.unit_reference;
      });
    },
    getStageValues() {
      return this.units.map((unit) => {
        return unit.stage_value;
      });
    },
    setSeries() {
      this.series = this.valuesToPercentage(this.getStageValues());
    },
    setChartMaxVal() {
      this.chartOptions.plotOptions.radialBar.dataLabels.value.maxVal =
        this.maxVal;
    },
  },
  watch: {
    units: function () {
      this.setSeries();
    },
  },
};
</script>

<style scoped>
.chart-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#radial-chart {
  width: 100%;
  height: 100%;
  /* scale: 1.4; */

  position: relative;
  z-index: 1;
}
</style>