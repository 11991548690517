<template>
  <main class="main-charts-container" v-if="stages.length">
    <section class="radial-chart-container">
      <RadialBarChart :stages="stages" :units="units" />
    </section>
    <section class="radar-chart-container">
      <RadarChart :stages="stages" />
    </section>
  </main>
</template>

<script>
import RadialBarChart from "../../components/charts/RadialBarChart.vue";
import RadarChart from "../../components/charts/RadarChart.vue";
export default {
  props: ["stages", "units"],
  components: { RadialBarChart, RadarChart },
};
</script>

<style scoped>
.main-charts-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.radial-chart-container {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.radar-chart-container {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>