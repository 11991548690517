<template>
  <main class="company-list-container">
    <section>
      <div class="subtitle primary-gradient-bg">
        <p class="-stb">Organizations</p>
      </div>
      <ProjectOverviewTable />
    </section>
    <section>
      <div class="subtitle primary-gradient-bg">
        <p class="-stb">Key Information</p>
      </div>
      <ProjectKeyInformation />
    </section>
  </main>
</template>

<script>
import ProjectOverviewTable from "../units/ProjectOverviewTable.vue";
import ProjectKeyInformation from "../units/ProjectKeyInformation.vue";
export default {
  components: { ProjectOverviewTable, ProjectKeyInformation },
  data() {
    return {
      filters: ["All time"],
    };
  },
};
</script>

<style scoped>
.company-list-container {
  width: 100%;
  display: flex;
  gap: 1rem;
}
.company-list-container > section {
  background: var(--white1);
  width: 100%;
  border-radius: 4px;
}
.company-list-container > section:last-child {
  border: 1px solid var(--gray6);
  height: max-content;
}
.subtitle {
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-block: 10px;
  padding-inline: 1rem;
  color: var(--white1);
  display: flex;
  user-select: none;
}

@media screen and (max-width: 1023px) {
  .company-list-container {
    flex-flow: column;
  }
}
</style>