<template>
  <main class="main-container">
    <p class="-xltb title">Project Units</p>
    <div class="card-container">
      <div class="card" v-for="unit in units" :key="unit">
        <div class="card-header">
          <img :src="unit.img" :alt="unit.unit_title" />
          <div class="bluritens">
            <div class="top-content-blur -xstb">
              <p>{{ unit.last }}</p>
              <p>{{ unit.unitTemplate }}</p>
            </div>
            <div class="bottom-content-blur -xstr">
              <p>{{ unit.update }}</p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="card-content">
            <p class="-stb">{{ unit.unit_title }}</p>
            <p class="-stb">{{ unit.tag }}</p>
          </div>
          <p class="-xstb">{{ unit.stage_value }}</p>
          <div class="-xstr btn-edit" @click="goToUnit(unit.id)">
            <p class="btn-edit">Edit Details</p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      units: [
        {
          id: 1,
          img: "https://images.unsplash.com/photo-1479839672679-a46483c0e7c8",
          name: "Building Ribeiro",
          tag: "RB_001",
          stage: "Stage: 6",
          last: "Last Updated",
          update: "19 Jan 2022",
          unitTemplate: "Building",
        },
        {
          id: 2,
          img: "https://images.unsplash.com/photo-1429041966141-44d228a42775",
          name: "Bridge Phoenix",
          tag: "BP_001",
          stage: "Stage: 4",
          last: "Last Updated",
          update: "12 Jan 2022",
          unitTemplate: "Bridge",
        },
        {
          id: 3,
          img: "https://images.unsplash.com/photo-1587445104203-e4aaf3994e57",
          name: "Sky Bridge",
          tag: "SKB_001",
          stage: "Stage: 3",
          last: "Last Updated",
          update: "08 Jan 2022",
          unitTemplate: "Building",
        },
        {
          id: 4,
          img: require("../../../src/assets/thumbnails/render.jpg"),
          name: "Block 01",
          tag: "BL_001",
          stage: "Stage: 2",
          last: "Last Updated",
          update: "05 Jan 2022",
          unitTemplate: "Building",
        },
        {
          id: 5,
          img: require("../../../src/assets/thumbnails/render.jpg"),
          name: "Block 02",
          tag: "BL_001",
          stage: "Stage: 2",
          last: "Last Updated",
          update: "05 Jan 2022",
          unitTemplate: "Building",
        },
        {
          id: 6,
          img: require("../../../src/assets/thumbnails/render.jpg"),
          name: "Block 03",
          tag: "BL_001",
          stage: "Stage: 0",
          last: "Last Updated",
          update: "01 Jan 2022",
          unitTemplate: "Building",
        },
      ],
    };
  },
  methods: {
    goToUnit(uid) {
      this.$router.push({ name: "UnitDetails", params: { uid } });
      // console.log(route);
    },
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-flow: column;
  background-color: white;
  /* overflow: auto; */
  width: 100%;
  justify-content: center;
}

.title {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  font-family: "Montserrat-Bold";
}
.card-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.card {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
  height: max-content;
}
.card-header img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}
.card-header {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.bluritens {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: max-content;
  width: 300px;
  line-height: 1;
  padding: 10px 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: rgba(19, 19, 19, 0.2);
  align-items: flex-start;
  justify-content: space-between;
  /* box-shadow: inset 8px 8px 30px black; */
  color: #fff;
}
.top-content-blur {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
}
.bottom-content-blur {
  padding: 0 10px;
}
.btn-edit {
  cursor: pointer;
  color: var(--highlight);
  user-select: none;
}
.btn-edit:hover {
  transform: scale(1.1);
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 4px;
  padding: 4px 10px;
  /* min-height: 80px; */
  margin-bottom: 10px;
}
.card-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* padding: 0 10px; */
}
</style>
