<template>
  <main class="chart-wrapper">
    <section id="radar-chart">
      <apexchart
        type="radar"
        height="208"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </section>
  </main>
</template>

<script>
export default {
  props: ["stages"],
  data() {
    return {
      series: [
        {
          name: "Series 1",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 100,
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radar: {
            size: 80,
            offsetX: 0,
            offsetY: 0,
            polygons: {
              strokeColors: false,
              strokeWidth: 1,
              connectorColors: "#ededed",
              fill: {
                colors: undefined,
              },
            },
          },
        },
        label: {
          show: false,
        },
        xaxis: {
          categories: [],
        },
        yaxis: { show: false },
      },
    };
  },
  created() {
    this.setRadarTicks();
    this.setCategories();
  },
  methods: {
    setRadarTicks() {
      this.series[0].data = this.stages.map((stage) => {
        return "";
      });
    },
    setCategories() {
      this.chartOptions.xaxis.categories = this.stages.map((stage) => {
        return stage.stage_value;
      });
    },
  },
};
</script>

<style scoped>
.chart-wrapper {
  position: relative;
  width: 100%;
  height: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#radar-chart {
  width: 100%;
  height: 100%;
  /* scale: 1.4; */
  /* margin-top: -70%; */
  position: absolute;
  z-index: 1;
  /* scale: 0.94; */

  overflow: hidden;
}
@media screen and (max-width: 960px) {
}
</style>